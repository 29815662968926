<script>
import { mapActions, mapGetters } from 'vuex';
import { Chart } from 'highcharts-vue';
import helper from '@/plugins/helper';
import USER_STATUS from '@/constants/user_statuses';

export default {
    name: 'Home',
    data: () => ({
        USER_STATUS: USER_STATUS,
        clients: {
            date_1: [
                helper.getDateHumanFormat(30),
                helper.getDateHumanFormat(),
            ],
            menu_1: false,
        },
    }),

    comments: {
        highcharts: Chart,
    },

    computed: {
        ...mapGetters('users', {
            users: 'getUserDashboard',
        }),

        getChartOptions() {
            return {
                title: '',
                reflow: true,
                xAxis: {
                    categories: this.getCategoriesChart(),
                },
                plotOptions: {
                    series: {
                        pointWidth: 20,
                    },
                },
                series: [
                    {
                        name: 'investor',
                        data: this.getInvestorData(),
                    },
                    {
                        name: 'founder',
                        data: this.getFounderData(),
                    },
                ],
                credits: {
                    enabled: false,
                },
            };
        },

        getRegistered() {
            return this.users.date_range ? this.users.date_range.registered : 0;
        },

        getChartData() {
            return this.users.date_range
                ? this.users.date_range.chart_data
                : {};
        },
    },

    methods: {
        ...mapActions('users', ['fetchUsersDashboard']),

        getCategoriesChart() {
            return Object.keys(this.getChartData);
        },

        getInvestorData() {
            let result = [];
            let chart_data = this.getChartData;
            for (let item in chart_data) {
                if (chart_data[item]['investor']) {
                    result.push(chart_data[item]['investor']);
                } else {
                    result.push(0);
                }
            }
            return result;
        },

        getFounderData() {
            let result = [];
            let chart_data = this.getChartData;
            for (let item in chart_data) {
                if (chart_data[item]['founder']) {
                    result.push(chart_data[item]['founder']);
                } else {
                    result.push(0);
                }
            }
            return result;
        },

        refreshDataUser() {
            this.getUsersData();
            this.clients.menu_1 = false;
        },

        resetDateUser() {
            this.clients.date_1 = [
                helper.getDateHumanFormat(30),
                helper.getDateHumanFormat(),
            ];
            this.clients.menu_1 = false;
        },

        async getUsersData() {
            let date = await this.getSortedDate(this.clients.date_1);
            this.clients.date_label_1 = date.date_start + ' - ' + date.date_end;
            await this.fetchUsersDashboard(date);
        },

        async getSortedDate(date) {
            return helper.sortDateRange(date);
        },

        routeToUsersByStatus(status) {
            this.$router.push(
                '/pages/users?status_1=' + status[0] + '&status_2=' + status[1],
            );
        },
    },
    async beforeMount() {
        await this.getUsersData();
    },
};
</script>

<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="12" style="text-align: center">
                <h2>Clients</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="5" offset="1">
                        <v-card>
                            <v-row>
                                <v-col cols="12">
                                    <div style="text-align: center">
                                        Quantity of clients:
                                        <b>{{ users.count_all }}</b>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div style="text-align: center">
                                        Approved clients:
                                        <b>{{ users.approved }}</b>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div style="text-align: center">
                                        New clients for today:
                                        <b>{{ users.today }}</b>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                        v-model="clients.menu_1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                class="date_range"
                                                dense
                                                label="Date range"
                                                append-icon="mdi-calendar"
                                                v-model="clients.date_label_1"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="clients.date_1"
                                            no-title
                                            range
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="resetDateUser"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="refreshDataUser"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-simple-table
                                        dense
                                        class="dashboard_table"
                                    >
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Clients
                                                    </th>

                                                    <th class="text-left">
                                                        Quantity
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Registered</td>
                                                    <td>{{ getRegistered }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <div style="text-align: center">
                                        Clients wait verification:
                                        <template
                                            v-if="users.wait_verification > 0"
                                        >
                                            <a
                                                @click="
                                                    routeToUsersByStatus([
                                                        USER_STATUS.PENDING,
                                                        USER_STATUS.REPEAT_PENDING,
                                                    ])
                                                "
                                            >
                                                <b>{{
                                                    users.wait_verification
                                                }}</b>
                                            </a>
                                        </template>
                                        <b v-else>
                                            {{ users.wait_verification }}
                                        </b>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="5" order="1">
                        <v-card>
                            <v-row>
                                <v-col cols="12">
                                    <highcharts
                                        :options="getChartOptions"
                                    ></highcharts>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.date_range input {
    text-align: center;
}
</style>
